import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme.min';
import 'tinymce/icons/default/icons.min';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import '../vendor/de'
import '../vendor/fr'

const tinyMce = (lang) => {
    tinymce.init({
        language: lang || 'en_US',
        selector: 'textarea.tinymce',
        plugins: ['table', 'lists', 'link'],
        toolbar: 'undo redo formatselect bold italic numlist bullist alignleft aligncenter alignright alignjustify outdent indent link',
        content_css: '/assets/stylesheets/content.css',
        skin: false,
    });

    // if you're using a language file, you can place its content here
}

export { tinyMce };

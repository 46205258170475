import 'bootstrap'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const jquery = require('jquery')
global.$ = global.jQuery = jquery;
window.$ = window.jQuery = jquery;

const moment = require('moment')
global.moment = moment;
window.moment = moment;

import { tinyMce } from "../vendor/tinyMce";

import 'bootstrap-table/dist/bootstrap-table.min'
import 'bootstrap-table/dist/locale/bootstrap-table-de-DE.min'
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.min'
import 'admin-lte/dist/js/adminlte.min'
import 'admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min'
import 'select2/dist/js/select2.min'
import 'select2/dist/js/i18n/de'
import 'select2/dist/js/i18n/fr'

import FsbApp from '../application';
window.FsbApp = FsbApp;
window.tinyMce = tinyMce;

import '../stylesheets/application.scss'

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover()
})

$(document).ready(function () {
    $('.has-tooltip').tooltip();
    $('.has-popover').popover({
        trigger: 'hover'
    });
    $('.loading_no_reset').on('click', function () {
        $(this).button('loading');
    });

    // Javascript to enable link to tab
    const url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('show.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });
});


JSON.flatten = function (data) {
    let result = {};

    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (let i = 0, l = cur.length; i < l; i++) {
                recurse(cur[i], prop + '[' + i + ']');
                if (l === 0) {
                    result[prop] = [];
                }
            }
        } else {
            let isEmpty = true;
            for (let p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + '.' + p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }

    recurse(data, '');
    return result;
};

'use strict';

const FsbApp = {

    numberPad: function (input, size) {
        let s = input + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    },

    formatDuration: function (input) {
        if (!input) {
            return '00:00';
        }

        const hours = Math.floor(input / 60);
        const minutes = input % 60;

        return FsbApp.numberPad(hours, 2) + ':' + FsbApp.numberPad(minutes, 2);
    },

    paramsToJsonApi: function (params, searchFields, defaultSort) {
        const f = 'q[' + searchFields.join('_or_') + '_cont]';
        const q = params.search ? params.search : '';
        const s = params.sort ? params.sort.replace('.', '_') : defaultSort;
        const o = {};
        o[f] = params.search;
        o['q[s]'] = s + ' ' + params.order;
        o['page_size'] = params.limit;
        o['page'] = Math.ceil(params.offset / params.limit) + 1;
        return o;
    },

    remoteInitSelect2: function ($select, path, id, key) {
        if (id) {
            //const $option = $('<option selected>Loading...</option>').val(id);
            //$select.append($option).trigger('change');
            $.ajax({ // make the request for the selected data object
                type: 'GET',
                url: path + '/' + id + '.json',
                dataType: 'json'
            }).then(function (data) {
                if (key) {
                    data = data[key];
                }

                const option = new Option(data.text, data.id, true, true);
                $select.append(option).trigger('change');

                $select.trigger({
                    type: 'select2:select',
                    params: {
                        data: data
                    }
                });

                // Here we should have the data object
                //$option.text(data.text).val(data.id + ''); // update the text that is displayed (and maybe even the value)
                //$option.removeData(); // remove any caching data that might be associated
                //$select.trigger('change'); // notify JavaScript components of possible changes
            });
        }
    },

    dataTable: function (id, url, config) {
        const element = $(`#${id}`);

        let settings = {};

        const storedSettings = sessionStorage.getItem(id);
        if (storedSettings !== null) {
            settings = JSON.parse(storedSettings);
        }

        const pageSize = settings.pageSize || 25;
        const pageNumber = settings.pageNumber || 1;

        element.bootstrapTable({
            url,
            striped: true,
            search: config.search,
            pagination: true,
            pageNumber,
            pageSize,
            sidePagination: 'server',
            clickToSelect: true,
            singleSelect: true,
            showColumns: config.showColumns,
            showRefresh: config.showRefresh,
            sortOrder: config.sortOrder || 'asc',
            queryParams: function (params) {
                return FsbApp.paramsToJsonApi(
                    params,
                    config.searchFields,
                    config.defaultSort,
                );
            },
            responseHandler: function (response) {
                return {
                    total: response.total,
                    rows: response[config.bucket]
                };
            },
            onPageChange: function (pageNumber, pageSize) {
                const value = {pageNumber, pageSize};
                sessionStorage.setItem(id, JSON.stringify(value));
            },
            onSearch: function () {
                sessionStorage.removeItem(id);
            },
            onSort: function () {
                sessionStorage.removeItem(id);
            },

            detailView: config.detailView,
            detailFormatter: config.detailFormatter,

            onClickRow: config.onClickRow,
            onDblClickRow: config.onDblClickRow,
            onLoadSuccess: config.onLoadSuccess,
            columns: config.columns,
        });

        return element;
    },

    expandTimeValue: function (elementId) {
        const element = $(`#${elementId}`);
        element.on('change', function (ev) {
            let curVal = element.val();
            if (curVal.length === 4) {
                curVal = curVal.substr(0, 2) + ':' + curVal.substr(2);
                element.val(curVal);
                element.trigger('input');
            } else if (curVal.length === 2) {
                curVal = curVal.substr(0, 2) + ':00';
                element.val(curVal);
                element.trigger('input');
            } else if (curVal.length === 1) {
                curVal = '0' + curVal.substr(0, 1) + ':00';
                element.val(curVal);
                element.trigger('input');
            }
        });
    }
};

export default FsbApp;